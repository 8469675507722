$size-3px: 3px;
$size-4px: 4px;
$size-6px: 6px;
$size-7px: 7px;
$size-8px: 8px;
$size-9px: 9px;
$size-10px: 10px;
$size-12px: 12px;
$size-14px: 14px;
$size-16px: 16px;
$size-18px: 18px;
$size-20px: 20px;
$size-24px: 24px;
$size-30px: 30px;
$size-32px: 32px;
$size-36px: 36px;
$size-42px: 42px;
$size-40px: 40px;
$size-49px: 49px;
$size-60px: 60px;
$size-72px: 72px;
$size-80px: 80px;
$size-100px: 100px;
