.container {
  min-height: 100vh;
}

.header {
  flex: 0.1;
}

.info {
  flex: 0.9;
  margin-top: 60px;
  font-size: 30px;
  font-weight: 600;
}
