@use "../../../styles/colors";
@use "../../../styles/size";

.container {
  flex: 1;
  padding: size.$size-6px;
}

.button-container {
  margin-top: size.$size-12px;
}

.auth-btn-icon {
  margin-right: size.$size-12px;
  font-size: 24px !important;
}

.link-container {
  font-size: size.$size-16px;
  text-align: center;
}

.sub-link {
  font-size: size.$size-12px;
  color: colors.$gray-color-600;
}
