@use "./styles/styles.scss";

body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  scrollbar-width: thin;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

// styles for find media list carousel
.carousel-root {
  display: flex;
  flex: 1;
}

.carousel .slide img {
  object-fit: contain;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  height: 400px;
}

/* stylelint-disable-next-line selector-class-pattern */
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  position: unset !important;
  height: 14px !important;
}

.gs-tree-node-item {
  background-color: transparent !important;
}

// rules for tooltip
.tippy-box {
  color: unset !important;
  background-color: var(--tootip-box) !important;
}

.tippy-content {
  padding: 6px !important;
  background-color: var(--tooltip-container-bg-color);
  border-radius: 4px !important;
}

// rules for date picker
.react-datepicker {
  background-color: inherit !important;
}

.react-datepicker__header {
  // eslint-disable-line
  background-color: inherit !important;
}

.react-datepicker__month-container {
  width: var(--date-picker-month-container-width) !important;
}

.react-datepicker__current-month {
  color: var(--date-picker-current-month-color) !important;
}

.react-datepicker__day {
  color: var(--date-picker-day-color) !important;
}

.react-datepicker__day--disabled {
  color: var(--date-picker-disabled-day-color) !important;
}

.react-datepicker__day--selected {
  color: var(--date-picker-selected-day-color) !important;
  background-color: var(--date-picker-selected-day-bg-color) !important;
}

.react-datepicker__day:hover {
  color: var(--date-picker-day-hover-color) !important;
  background-color: var(--date-picker-day-hover-bg-color) !important;
}

.react-datepicker__day--in-range {
  color: var(--date-picker-selected-day-color) !important;
  background-color: var(--date-picker-selected-day-bg-color) !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: var(--date-picker-range-selector-bg-color) !important;
}

.react-datepicker__day-name {
  color: var(--date-picker-weekdays-color) !important;
}

.epr-category-nav {
  padding: 0 !important;
}

.swiper .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}

.material-icons-outlined,
.material-icons {
  cursor: pointer;
}
