.container {
  height: 100%;
}

.header {
  flex: 0.3;
}

.footer {
  flex: 0.2;
}
