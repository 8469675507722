$theme-light-background: #fff;
$theme-dark-background: #000;
$theme-light-text: #000;
$theme-dark-text: #fff;
$green-color: #34a853;
$white-color: #fff;
$gray-color: #4a4a4a;
$red-color: #f00;
$black-color: #000;
$gray-color-100: #f0f0f0;
$gray-color-200: #f2f2f2;
$gray-color-300: #868686;
$gray-color-400: #e8e8e8;
$gray-color-500: #7b7b7b;
$gray-color-600: #9b9b9b;
$gray-color-800: #979797;
$gray-background-color: #ffffffc4;
$badge-color: #ea4335;
$input-border-color: #80808061;
$blue-link: #4285f4;
$modal-background: #fff8;
$translucent-background: rgb(0 0 0 / 40%);
