.img-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fffc;
}

.inner-container {
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-container {
  margin-top: 31px;
}

.loader-text {
  display: block;
  padding: 0 12px;
  font-size: 18px;
  color: #4a4a4a;
  white-space: pre-wrap;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
