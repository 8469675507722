@use "../../../styles/colors";

.img-container img {
  width: 100px;
  height: 100px;
}

.img-container span {
  font-size: 60px;
  font-weight: 500;
  color: colors.$gray-color;
}
