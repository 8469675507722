/* stylelint-disable keyframes-name-pattern */
// @use "~bootstrap/scss/bootstrap";
@import "~@gsynergy/gs-react-components/lib/styles/index.css";
@import "./colors";
@import "./size";

.d-flex {
  display: flex;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.overflow-unset {
  overflow: unset;
}

.overflow-hidden {
  overflow: hidden;
}

.height-auto {
  height: auto !important;
}

.fd-row {
  flex-direction: row;
}

.overflow-scroll {
  overflow: scroll;
}

.fd-column {
  flex-direction: column;
}

.flex-one {
  flex: 1 1 0;
}

.bs-border-box {
  box-sizing: border-box;
}

.al-center {
  align-items: center;
}

.al-start {
  align-items: flex-start;
}

.al-end {
  align-items: end;
}

.jc-start {
  justify-content: start;
}

.jc-end {
  justify-content: end;
}

.jc-center {
  justify-content: center;
}

.jc-space-around {
  justify-content: space-around;
}

.f-wrap {
  flex-wrap: wrap;
}

.ellipse-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jc-spc-btwn {
  justify-content: space-between;
}

.dont-break-out {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.hyphens {
  hyphens: auto;
}

.postition-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed;
}

.postition-absolute {
  position: absolute !important;
  top: 100px !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-8px {
  margin-top: 8px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.radius-3px {
  border-radius: 3px;
}

.border-none {
  border: none;
}

.min-h-250px {
  min-height: 250px;
}

.min-h-200px {
  min-height: 200px !important;
}

.h-400px {
  height: 400px;
}

.outline-none {
  outline: none;
}

.h-100 {
  height: 100vh !important;
}

.width-30-percent {
  width: 30%;
}

.width-40-percent {
  width: 40%;
}

.width-50-percent {
  width: 50%;
}

.w-100percent {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.width-300px {
  width: 300px;
}

.padding-y-16px {
  padding: 16px 0;
}

.h-120px {
  height: 120px;
}

.h-100px {
  height: 100px;
}

.object-fit-cover {
  object-fit: cover;
}

.border-radius-full {
  border-radius: 100%;
}

.right-6px {
  right: 6px;
}

.top-6px {
  top: 6px;
}

.color-green {
  color: green;
}

.gap-12px {
  gap: 12px;
}

.max-h-min-content {
  max-height: min-content;
}

.height-min-content {
  height: min-content;
}

.p-4px {
  padding: 4px;
}

.p-10px {
  padding: 10px;
}

.p-12px {
  padding: 12px;
}

.px-12px {
  padding: 0 12px;
}

.px-4px {
  padding: 0 4px !important;
}

.padding-left-24px {
  padding-left: 24px;
}

.border-radius-5px {
  border-radius: 5px;
}

.border-radius-6px {
  border-radius: 6px;
}

.min-h-140px {
  min-height: 140px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-12px {
  font-size: 12px !important;
}

.min-w-120px {
  min-width: 120px;
}

.m-left-6px {
  margin-left: 6px;
}

.w-200px {
  width: 200px;
}

.pb-2px {
  padding-bottom: 2px;
}

.no-scroll-bars::-webkit-scrollbar {
  display: none !important;
}

.overflowx-hidden {
  overflow-x: hidden !important;
}

.scrollbar-thin {
  scrollbar-width: thin !important;
}

.min-w-400px {
  min-width: 400px;
}

.gap-4px {
  gap: 4px;
}

.gap-6px {
  gap: 6px;
}

.gap-8px {
  gap: 8px;
}

.text-center {
  text-align: center;
}

.w-350px {
  width: 350px;
}

.min-w-300px {
  min-width: 300px !important;
}

.h-175px {
  height: 175px;
}

.h-200px {
  height: 200px;
}

.pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center !important;
}

.overflow-auto {
  overflow: auto;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.align-self-center {
  align-self: center;
}

.align-self-flex-start {
  align-self: flex-start !important;
}

.align-self-flex-end {
  align-self: flex-end !important;
}

.width-100p {
  width: 100% !important;
}

.width-zero {
  width: 0;
}

.min-width-100-percent {
  min-width: 100% !important;
}

.min-height-100-percent {
  min-height: 100% !important;
}

.height-100p {
  height: 100% !important;
}

.height-70vh {
  max-height: 70vh !important;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.text-decoration-none {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.webkit-line-clamp-2 {
  -webkit-line-clamp: 2;
}

.webkit-line-clamp-1 {
  -webkit-line-clamp: 1;
}

.webkit-box-orient {
  -webkit-box-orient: vertical;
}

.position-sticky {
  position: sticky;
}

.top-zero {
  top: 0;
}

.left-zero {
  left: 0;
}

.right-zero {
  right: 0;
}

.bottom-zero {
  bottom: 0;
}

.border-radius-50-percent {
  border-radius: 50%;
}

.scroll-behaviour-smooth {
  scroll-behavior: smooth;
}

.z-index-999 {
  z-index: 999;
}

.z-index-99 {
  z-index: 99;
}

.object-fit-contain {
  object-fit: contain !important;
}

.max-width-100-percent {
  max-width: 100% !important;
}

.p-8px {
  padding: 8px;
}

.max-width-50-vw {
  max-width: 50vw;
}

.max-height-100-percent {
  max-height: 100%;
}

.horizontal-reverse {
  transform: scaleX(-1);
}

.line-height-normal {
  line-height: normal;
}

.text-align-end {
  text-align: end;
}

.min-height-max-content {
  min-height: max-content;
}

.max-width-max-content {
  max-width: max-content;
}

.width-max-content {
  width: max-content;
}

.max-width-zero {
  max-width: 0;
}

.border-top-right-radius-16px {
  border-top-right-radius: 16px;
}

.list-style-none {
  list-style: none;
}

.text-transform-none {
  text-transform: none !important;
}

.color-link {
  color: $blue-link !important;
}

.width-18px {
  width: 18px !important;
}

.font-size-18px {
  font-size: 18px !important;
}

.word-break-all {
  word-break: break-all;
}

.border-radius-top-right-0px {
  border-top-right-radius: 0 !important;
}

.border-radius-top-left-0px {
  border-top-left-radius: 0 !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.height-32px {
  height: 32px !important;
}

.width-32px {
  width: 32px !important;
}

.height-48px {
  height: 48px !important;
}

.width-48px {
  width: 48px !important;
}

.w-160px {
  width: 160px;
}

.h-160px {
  height: 160px;
}

.w-170px {
  width: 170px;
}

.h-170px {
  height: 170px;
}

.width-100svw {
  width: 100svw !important;
}

.-left-5px {
  left: -5px !important;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.margin-auto {
  margin: auto;
}

.pt-12px {
  padding-top: 12px !important;
}

.virtuoso_list:first-child {
  display: flex;
  width: calc(100svw - 1%);
  padding-top: 0;
}
